import React,{Component} from 'react'
import ReactMustache from 'react-mustache'
import ReactDOM from 'react-dom'

class Print extends Component{

    constructor(){
        super()
        this.state = {
            width:"100%"
        }
    }

    async componentDidMount()
    {
        let printHeight= 0 
        if(this.props.printData.PrintConfig.PrintSize ==="2 inch")
        {
            this.setState({width:"250px"})
        }
        if(this.props.printData.PrintConfig.hasOwnProperty('PrintHeight') && this.props.printData.PrintConfig.PrintHeight !==0)
        {
            printHeight = this.props.printData.PrintConfig.PrintHeight
        }
        else
        {
            if(this.props.printData.PrintConfig.PrintSize ==="A4")
            {
                printHeight = 1000
            }
            else if(this.props.printData.PrintConfig.PrintSize ==="A5")
            {
                if(this.props.printData.PrintConfig.hasOwnProperty('PrintOrientation') && this.props.printData.PrintConfig.PrintOrientation==="landscape")
                {
                        printHeight = 600
                }
                else
                {
                        printHeight = 720
                }
            }
            else
            {
                printHeight = 0
            }
        }
        let rowCount = 0;
        if(this.props.printData.hasOwnProperty('Sale'))
        {
            //    alert(printHeight)
            rowCount =  this.props.printData.Sale.LineItems.length 
           
        }
        if(this.props.printData.hasOwnProperty('Inventory'))
        {
            //    alert(printHeight)
            rowCount =  this.props.printData.Inventory.LineItems.length    
        }
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = "var footer = document.getElementById('footer'); if(footer!==null){ var footerHeight = document.getElementById('footer').offsetHeight;var footerTop =document.getElementById('footer').offsetTop;var trheight = " + printHeight + " - (footerTop + footerHeight);document.getElementById('" +  rowCount +  "').style.height = trheight + 'px';  } ";
        this.instance.appendChild(s);

        this.setState({updateState:true})
    }

    async componentDidUpdate()
    {
        let HtmlBody = ReactDOM.findDOMNode(this).innerHTML
        if(this.props.printData.PrintConfig.PrintSize ==="3 inch" && !this.props.isMobileApp())
        {
            HtmlBody = "<div style='width:300px'>"+ HtmlBody+ "</div>"
        }
        if(this.props.printChecked)
        {
            window.print()
        }
        if(this.props.sendEmailChecked)
        {
            if (this.props.printData.hasOwnProperty('Sale'))
            {
                if(this.props.printData.Sale.EmailID.trim()==="")
                {
                    return 
                }
                let email={
                    RegistrationID:this.props.printData.Sale.RegistrationID,
                    StoreID:this.props.printData.Sale.Store.key,
                    UserID:this.props.printData.Sale.ActionByUID,
                    To:this.props.printData.Sale.EmailID,
                    From: "noreply@posible.in",
                    Subject:"Invoice #" + this.props.printData.Sale.TransactionNo,
                    TextBody:"Invoice",
                    HTMLBody:HtmlBody ,
                }
                this.props.sendEmail(email)
                this.props.getToastr("Email sent")
            }
            else if (this.props.printData.hasOwnProperty('Inventory'))
            {
                if(this.props.printData.Inventory.EmailID.trim()==="")
                {
                    return 
                }
            
                let email={
                    RegistrationID:this.props.printData.Inventory.RegistrationID,
                    StoreID:this.props.printData.Inventory.Store.key,
                    UserID:this.props.printData.Inventory.ActionByUID,
                    To:this.props.printData.Inventory.EmailID,
                    From: "noreply@posible.in",
                    Subject: this.props.printData.Inventory.TransactionType.TransactionType + " #" + this.props.printData.Inventory.TransactionNo,
                    TextBody:"Invoice",
                    HTMLBody:HtmlBody ,
                }
                this.props.sendEmail(email)
                this.props.getToastr("Email sent")
            }
        }
        if(this.props.sendSMSChecked){
            let sale = {
                key: this.props.printData.Sale.key,
                TransactionNo: this.props.printData.Sale.TransactionNo,
                BillAmount: this.props.printData.Sale.BillAmount,
                BalanceAmount: this.props.printData.Sale.BalanceAmount,
                BillPaidAmount: this.props.printData.Sale.BillPaidAmount,
                TransactionType: this.props.printData.Sale.TransactionType,
                WalletBalance: this.props.printData.Sale.WalletBalance,
                Store: this.props.printData.Sale.Store,
                Customer: this.props.printData.Sale.Customer,
            };
            let sms={
                RegistrationID:this.props.printData.Sale.RegistrationID,
                StoreID:this.props.printData.Sale.Store.key,
                UserID:this.props.printData.Sale.ActionByUID,
                Module:"sales",
                TransactionType: this.props.printData.Sale.TransactionType.TransactionType,
                TransactionData: sale,
                MobileNo: this.props.printData.Sale.SMSNo
            }
            // console.log("sms", sms);
            this.props.sendSMS(sms)
        }
        if(this.props.sendWhatsAppChecked){
            let sale = {
                key: this.props.printData.Sale.key,
                TransactionNo: this.props.printData.Sale.TransactionNo,
                BillAmount: this.props.printData.Sale.BillAmount,
                BalanceAmount: this.props.printData.Sale.BalanceAmount,
                BillPaidAmount: this.props.printData.Sale.BillPaidAmount,
                TransactionType: this.props.printData.Sale.TransactionType,
                WalletBalance: this.props.printData.Sale.WalletBalance,
                Store: this.props.printData.Sale.Store,
                Customer: this.props.printData.Sale.Customer,
            };
            
            let invoiceURL = '', message = ''
            if (this.props.registration.registration && this.props.registration.registration.hasOwnProperty('SendInvoiceLink') && this.props.registration.registration.SendInvoiceLink) {
                invoiceURL = "https://app.posible.in/s/" + sale.key;
            }

            if (invoiceURL !== '')
                message = "Thank you for your purchase worth Rs. " + sale.BillAmount + " of Transaction No: " + sale.TransactionNo + " from " + sale.Store.StoreName + ". Click here " + invoiceURL + " details"
            else
                message = "Thank you for your purchase worth Rs. " + sale.BillAmount + " of Transaction No: " + sale.TransactionNo + " from " + sale.Store.StoreName + "."
            
            window.open('https://wa.me/91' + sale.Customer.PhoneNo.trim() + '/?text=' + encodeURI(message), true);

        }
        this.props.onClose();
    }

    render(){
        return <React.Fragment >
            <div id="print" style={{width:this.state.width}} ref={el => (this.instance = el)} >
                {this.props.printData !== null && 
                    <ReactMustache 
                        template={this.props.printData.PrintConfig.PrintTemplate}  
                        data={this.props.printData}/>
                }
            </div>
        </React.Fragment>
    }
}

export default Print