import React, { Component } from 'react';
import { useParams } from "react-router-dom";
import Spinner from './Spinner';
import Print from './Print';
import axios from 'axios';

class App extends Component {

  constructor(){
    super();
    this.state={
      loading:true
    }
    this.getSaleDetails=this.getSaleDetails.bind(this);
    this.getPrintConfigByID = this.getPrintConfigByID.bind(this);
    this.printFormatting = this.printFormatting.bind(this);
    this.roundUpTo2 = this.roundUpTo2.bind(this);
    this.convertNumberToWords = this.convertNumberToWords.bind(this);
  }

  async getSaleDetails(key)
  {
      let response = null;
      let url= "https://us-central1-posible-app-prod.cloudfunctions.net/apiGetSaleDetailsV1";
      response = await axios
        .post(url, {
          key:key
        })
        .then(result => {
            return result.data.data;
        })
        .catch(err => {
          alert("getSaleDetails failed:" + JSON.stringify(err))
        });
        return response;
  };

  async getPrintConfigByID(printConfigID){
    let response = null;
    let url= "https://us-central1-posible-app-prod.cloudfunctions.net/apiGetPrintConfigByIDV1";
    response = await axios
      .post(url, {
        key:printConfigID
      })
      .then(result => {
          return result.data.data;
      })
      .catch(err => {
        alert("getPrintConfigByID failed:" + JSON.stringify(err))
      });
      return response;
  }

  async getPrintConfigs(registrationID, storeID){
    let response = null;
    let url= "https://us-central1-posible-app-prod.cloudfunctions.net/getPrintConfigsV1";
    response = await axios
      .post(url, {
        registrationID:registrationID,
        storeID:storeID
      })
      .then(result => {
          return result.data.data;
      })
      .catch(err => {
        alert("getPrintConfigs failed:" + JSON.stringify(err))
      });
      return response;
  }

  convertNumberToWords(amount) {
        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        amount = amount.toString();
        var atemp = amount.split(".");
        var number = atemp[0].split(",").join("");
        var n_length = number.length;
        var words_string = "";
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (var i = 9 - n_length, j = 0; i < 9; i++ , j++) {
                n_array[i] = received_n_array[j];
            }
            for (var i = 0, j = 1; i < 9; i++ , j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            let value = "";
            for (var i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Crores ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Lakhs ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += "Hundred and ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }
        return "Rupees " + words_string;
  }

  roundUpTo2(num) {
    return Math.round(Number(num) * 100) / 100;
  }

  printFormatting(sale){
        
    console.log('sale.TransactionDate ',sale.TransactionDate )
    sale.TransactionDate = (new Date(sale.TransactionDate._seconds*1000)).toString().substring(0,24)  
    console.log('sale.TransactionDate ',sale.TransactionDate )

    sale.AmountInWords= this.convertNumberToWords(sale.BillAmount)  
    sale.LineItems.map((lineItem, index) => {
        sale.LineItems[index].SrNo = index +1
    })
    let printTaxComponents= []
    sale.LineItems.map((lineItem, index) => {
        if(lineItem.TaxID !="")
        {
            lineItem.TaxComponentAmount.map((t)=>{
                let indexFound = -1 
                printTaxComponents.map((pt,ptIndex)=>{
                    if(t.TaxType===pt.TaxType)
                    {
                        indexFound = ptIndex
                        return
                    }
                })
                if(indexFound<0)
                {
                    printTaxComponents.push({ TaxType:t.TaxType,
                        TaxAmount:this.roundUpTo2(Number(t.TaxAmount))
                       })
                }
                else
                {
                    printTaxComponents[indexFound].TaxAmount =this.roundUpTo2( Number(printTaxComponents[indexFound].TaxAmount )+ Number(t.TaxAmount))
                }
            })
        }
    })
    sale.PrintTaxComponents = printTaxComponents
    sale.LineItems.map((lineItem, index)=>{
        for(var i=0; i<printTaxComponents.length; i++ )
        {
            if(lineItem.TaxComponentAmount.length<(i+1))
            {
                lineItem.TaxComponentAmount.push({TaxName:'', TaxAmount:0, TaxPercentage:'',TaxType:''})
            }
        }
    })
  }

  async componentDidMount(){
    console.log('useParams', this.props.params.transactionID);
    let key = this.props.params.transactionID;
    let sale = null
    let printConfig = null
    let printData = null
    if(key.trim()!=="")
    {
        sale = await this.getSaleDetails(key)
        console.log('sale',sale)
        if(sale)
        {
           if(sale.hasOwnProperty("PrintConfigID") && sale.PrintConfigID!==""){
            printConfig = await this.getPrintConfigByID(sale.PrintConfigID)
           }
           else
           {
            //get all print configs
            let prinConfigs = await this.getPrintConfigs(sale.RegistrationID, sale.Store.key);
            if(prinConfigs.filter(f=>f.TransactionTypeID=== sale.TransactionType.key && !f.IsDeleted).length>0){
                printConfig=prinConfigs.filter(f=>f.TransactionTypeID=== sale.TransactionType.key && !f.IsDeleted)[0]
            }
            // console.log('prinConfigs',prinConfigs);
           }
            console.log('printConfig',printConfig)
            this.printFormatting(sale)
           
        }
        printData = {Sale:sale,PrintConfig:printConfig}
        console.log('printData',printData);
        this.setState({printData:printData, loading:false})
    }
  }

  render(){
    return (
      <div className="App">
        {this.state.loading && <Spinner/>}
        {!this.state.loading && <Print  
            {...this.props}   
            printView={true}
            printChecked = {false} 
            sendEmailChecked={false}
            sendSMSChecked = {false}
            printData = {this.state.printData}
            onClose={()=> console.log('print done')}/> }
      </div>
    );
  }
  
}

export default (props) => (
  <App
      {...props}
      params={useParams()}
/>)
